﻿.RocktimeFieldErrorGroup, .RocktimeFieldErrorMarkerBorder, .RocktimeFieldErrorCommon, .RocktimeFieldErrorRightPadding, .RocktimeFieldErrorRightPadding2, .RocktimeFieldErrorWrapper, .RocktimeFieldErrorCheckBox label::before,
.RocktimeFieldErrorCheckBox .switch-toggle, .RocktimeFieldErrorMarker .rlbGroup, .RocktimeFieldErrorMarker .riEnabled, .RocktimeFieldErrorMarker .riHover, .RocktimeFieldErrorMarker iframe, .RadComboBox.RocktimeFieldErrorMarker > table,
.RocktimeFieldErrorMarker.riSingle > input, .RocktimeFieldErrorRadTextBoxMultiLine.rtTACView, .RocktimeFieldErrorRadTextBoxMultiLine textarea {
    border-radius: 4px;
    border: 1.5px solid #FF0000;
    border-color: #FF0000 !important;
}

.RocktimeFieldErrorCommon, .RocktimeFieldErrorRightPadding, .RocktimeFieldErrorRightPadding2, .RocktimeFieldErrorWrapper, .RocktimeFieldErrorMarker .rlbGroup,
.RocktimeFieldErrorMarker .riEnabled, .RocktimeFieldErrorMarker .riHover, .RocktimeFieldErrorMarkerPadding .riEnabled, .RocktimeFieldErrorMarkerPadding .riHover,
.RocktimeFieldErrorMarkerPadding iframe, .RocktimeFieldErrorMarker.riSingle > input, .RocktimeFieldErrorRadTextBoxMultiLine {
    background-color: #FFFFFF !important;
}

.checklist .RocktimeFieldErrorWrapper {
    background-color: transparent !important;
}

.RocktimeFieldErrorWrapper {
    display: block;
    margin-left: -5.5px;
    margin-top: -5.5px;
    width: 100%;
    padding: 4px;
}

.RocktimeFieldErrorWrapper + span {
    margin-top: 10px;
}

.RocktimeFieldErrorGroup span.RadButton {
    border: none;
}

.RocktimeFieldErrorCheckBox {
    background-color: transparent !important;
    margin: 0;
    display: table;
    min-height: 25px;
}

.RocktimeFieldErrorCheckBox .switch-toggle {
    margin: -2px 8px 0px -4px;
    padding: 2px 0 0 2px;
    width: 100%;
}

.rlbItem.rlbSelected {
    background-color: rgba(197,197,197,0.3) !important;
}

.RocktimeFieldErrorDate .date-combobox-child .RadComboBox, .RocktimeFieldErrorDate .date-droplist-child .RadDropDownList {
    border-radius: 4px;
    border: 1.5px solid #FF0000;
}

.RadEditor.RocktimeFieldErrorMarker {
    & .reLayoutWrapper {
        iframe {
            border-radius: 4px;
            border: 1.5px solid #FF0000;
        }
    }
}
