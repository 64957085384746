﻿.c-icon {
    display: block;

    > svg {
        height: 100%;
        width: 100%;
    }

    &--pin {
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        transition: transform 0.1s linear;

        &:hover {
            transform: rotate(0deg);
        }

        &.active {
            transform: rotate(0deg);


            &:hover {
                transform: rotate(45deg);

                &.disabled {
                    transform: rotate(0deg);
                }
            }
        }

        &.disabled {
            &:hover {
                transform: rotate(45deg);
            }

            svg {
                fill: $color-disabled;
            }
        }
    }
}
