﻿.c-file-upload {
    $root: &;
    border: 2px solid #E0E8EC;
    padding: 0 8px;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 14px;

    &:hover {
        border-color: #BEBEBE;
    }

    .button {
        &:focus {
            outline: 1px solid #000000;
        }
    }

    &__file-opts {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.6rem;
        font-size: 14px;

        &--hidden {
            display: none;
        }
    }

    &__file-details {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 0.6rem;
        font-size: 14px;

        &--hidden {
            display: none;
        }

        &:hover {
            border-color: #BEBEBE;
        }
    }

    &__delete,
    &__replace {
        margin-bottom: 1rem;
    }

    &__file-cta {
        /*display: flex;*/
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        align-self: center;
        padding-left: 1rem;
        font-size: 16px;
        text-align: right;

        .button {
            display: inline-block;

            &:first-child {
                margin: 0.6rem 0 0 0.6rem;
            }

            &:not(:first-child) {
                margin: 0.6rem 0 0 0.6rem;
            }
        }

        label {
            white-space: nowrap;
        }
    }

    &__info {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
        margin-top: 0.6rem;
    }

    &__file-text {
        display: inline-block;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }

    &__opt-ctas {
        /*display: flex;*/
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        align-self: center;
        text-align: right;

        .button {
            display: inline-block;

            &:first-child {
                margin: 0.6rem 0 0 0.6rem;
            }

            &:not(:first-child) {
                margin: 0.6rem 0 0 0.6rem;
            }
        }
    }

    &__view-image {
        min-width: 35px;
        margin-right: 1rem;
        word-break: break-word;
    }

    &__view-file {
        min-width: 35px;
        margin-right: 1rem;
        word-break: break-word;

        img {
            min-height: 35px;
        }
    }

    &__upload-msg {
        margin: 0.6rem 0 0 0;
    }

    &__upload-box {
        &:hover {
            cursor: pointer;
        }
    }

    &__input {
        &:focus + #{$root}__upload-msg + #{$root}__file-cta > #{$root}__upload-box {
            outline: 1px solid #000000;
        }
    }

    input {
        position: absolute !important;
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
        overflow: hidden !important;

        &:focus + div > label {
            outline: 1px solid #000000;
        }
    }
}
